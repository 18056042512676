.stars-container {
  margin-bottom: 12px;
}

.custom-form-input,
.custom-form-textarea {
  background-color: #f8f8f8;
  width: 100%;
  height: 50px;
  border: 1px solid #f8f8f8;
  padding: 6px 15px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.custom-form-textarea {
  height: 100px;
}

.custom-input-wrapper {
  margin-bottom: 20px;
}

.error-paragraph {
  font-size: 12px;
  color: red;
  padding-left: 8px;
  margin: 0;
}

.custom-form-submit input {
  max-width: 180px;
  background: var(--color-primary-1);
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  padding: 8px;
  margin-top: 5px;
}

@media screen and (min-width: 768px) {
  .custom-input-wrapper {
    width: 48%;
  }
  .custom-form-inputs-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
